import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, LinearProgress, Snackbar } from '@mui/material';
import api from '../services/api';
import { FormControl } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from "moment";
import 'moment/locale/pt-br'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';



export default function FormDialog({ info, handlerClose, open }) {
  const [loading, setLoading] = React.useState(false)
  const [history, setHistory] = React.useState([])
  React.useEffect(() => {
    if (open) gethistory()
    if (!open) setHistory([])
  }, [open, handlerClose.triggerid])


  const gethistory = () => {
    if (!info.triggerid == undefined) {
      setHistory([])
      return
    }
    setLoading(true)
    api.get(`dashboard/history/${info.cod_empresa}/${info.triggerid}/`)
      .then(res => {
          setHistory(res.data)   
      }).catch(err => {setHistory([])
        
      }).finally(() => setLoading(false))

  }





  return (

      <Dialog open={open} fullWidth={true}  scroll={'paper'}
        maxWidth='lg'>
        
          <DialogTitle>
            Histórico:
          </DialogTitle>
          <DialogContent>
            <TableContainer  component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Descrição</TableCell>
                    <TableCell align="center">Horário</TableCell>
                    <TableCell align="center">Vistos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.length>0 ? history.map((row, i) => (
                    <TableRow
                      key={row.objectid+i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{moment.unix(row.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                      <TableCell align="right">{row.acknowledges?.map(ack=>
                        <Alert key={i} sx={{mt: 1, backgroundColor: grey[1000]}} severity="info">
                        <Typography>{moment.unix(ack.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} - Vistado {moment.unix(ack.clock).locale('pt-br').fromNow()}:</Typography>
                        {ack.message} </Alert>
                        )}</TableCell>
                    </TableRow>
                  )): null}
                </TableBody>
              </Table>
            </TableContainer>

                    {loading && <LinearProgress sx={{mt: 2}}  />}
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={handlerClose}>Fechar</Button>

          </DialogActions>
      </Dialog>
  
  );
}
