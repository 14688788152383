import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../theme'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { module } from './FormDialog'
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'

export default function(){
    const navigate = useNavigate()
    const [ handlerDialog, setHandlerDialog ] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const  auth = useAuth()
    React.useEffect(()=>{
        setUpdate(false)
        updateData()

    },[handlerDialog,update])
    const [data, setData] = React.useState()
    React.useEffect(()=>{
        document.title = "Horus - Cargos"
      },[])

    const columns =[
        {
            name:'id',
            label:'Id',
            
        },
        {
            name:'description',
            label: "Descrição",
            options: {
                filter: true,
                sort: true,}
        },
        {
            name: "actions",
            label: "Ações",
            options:{
              customBodyRenderLite : (dataIndex, rowIndex)=>
              <>
                <Button sx={{ color:theme.palette.text.primary}}  disabled={!auth.hasPerm('role.edit')} onClick={()=>{
                    const id = data[dataIndex].id
                    setId(id)
                    setHandlerDialog(true)
                }}>
                <EditIcon/>
            </Button>
                <Button disabled={!auth.hasPerm('role.delete')} sx={{ color:theme.palette.text.primary}} onClick={()=>{
                    const id = data[dataIndex].id
                    const name = data[dataIndex].description 
                    deleteItem(id, name)
                    
                }}>
                <DeleteIcon/>
            </Button>
            </>
            
            }
          }
    ]
    const deleteItem =(id, name)=>{
        const conf = confirm(`Deseja realmente apagar o cargo: ${name}`)
        if(!conf) return
        api.delete(`${module}/delete/${id}/`).then(()=>{
           setUpdate(true)
        }).catch(err => console.log(err))
    }
    const updateData = ()=>{
        api.get(`${module}/all`)
        .then(res => setData(res.data)).catch((err) => {
            if(err.response.status==401){
              navigate('/401')
            }
            setData()
          })
    }
    const showFormDialog = ()=>{
        setId()
        setHandlerDialog(true)
    }
    const options = {
        filterType: 'checkbox',
        rowsPerPageOptions : [30, 50, 100, 500],
        rowsPerPage: 30,
        rowHover: false,
        print: false,
        selectableRows: 'none' ,
        download: false,
        customToolbar : ()=> <IconButton onClick={showFormDialog} disabled={!auth.hasPerm('role.create')} sx={{color: theme.palette.text.primary }}><AddIcon/></IconButton>, 
        customToolbarSelect: () => {},
    }
    return(
        <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            <ThemeProvider theme={theme}>
            <FormDialog open={handlerDialog} id={id} handlerClose={()=>setHandlerDialog(false)} updateData={updateData}/>
            <Box sm={12} lg={10}>
            <MUIDataTable
            title={"Cargos e Permissões"}
            data={data}
            columns={columns}
            options={options}
            />
            </Box>
        </ThemeProvider>
          </Container>
    )
}