import React from 'react'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import theme from '../theme';
import { Link } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningIcon from '@mui/icons-material/Warning';
import HistoryIcon from '@mui/icons-material/History';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import { useAuth } from '../auth/AuthProvider';


export default function ListMenu() {
    const handlerLoc = (item) => JSON.parse(localStorage.getItem(item)) || false
    const auth = useAuth()
    const [open, setOpen] = React.useState({
        reportMenu: handlerLoc('reportMenu_historico')
    });

    return (
        
            <>
                <ListItemButton onClick={() => {
                    setOpen(open => ({ ...open, reportMenu: !open.reportMenu }))
                    localStorage.setItem('reportMenu_historico', !open.reportMenu)
                    console.log('reportMenu_historico', open)
                }}
                    sx={{
                        backgroundColor: open?.userMenu ? theme.palette.mode == 'dark' ? grey[900] : grey[100] : theme.palette.mode == 'dark' ? grey[900] : grey[100],
                        color: theme.palette.mode == 'dark' ? grey[100] : grey[900]
                    }}>
                    <ListItemIcon>
                        <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hist. de Alarmes" />
                    {open?.reportMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.reportMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{
                        pl: 1,
                        backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                        color: theme.palette.mode == 'dark' ? grey[200] : grey[900]
                    }} >

                        <>
                            <Link to={'/report/history'} style={{
                                textDecoration: 'none',
                                backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                            }}>
                                <ListItemButton  >
                                    <ListItemIcon>
                                        <WarningIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Geral" />
                                </ListItemButton>
                            </Link>
                            {auth.hasPerm('report.get') ?
                                <>
                                    <Link to={'/report/empresa'} style={{
                                        textDecoration: 'none',
                                        backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                        color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                                    }}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Empresas" />
                                        </ListItemButton>
                                    </Link>

                                    <Link to={'/report/host'} style={{
                                        textDecoration: 'none',
                                        backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                        color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                                    }}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                <ContentPasteSearchIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Hosts" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to={'/report/tecnica'} style={{
                                        textDecoration: 'none',
                                        backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                        color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                                    }}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                <GroupIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Colaboradores" />
                                        </ListItemButton>
                                    </Link>
                                </>
                                : ''}
                        </>

                    </List>
                </Collapse>
            </>
         
    )
}
