import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, LabelList, PieChart, Pie, Text, ResponsiveContainer, Cell } from 'recharts';

import { Box, Typography } from "@mui/material";
import { blue, blueGrey, brown, cyan, deepOrange, green, grey, lime, orange, pink, purple, red } from "@mui/material/colors";
import { GraphBox } from "../GraphBox";

const colors = [
    "#e3f2fd",
    "#90caf9",
    "#4caf50",
    "#1976d2",
    "#0d47a1",
    "#f44336",
    "#e8f5e9",
    "#388e3c",
    "#2196f3",
    "#1b5e20",
    "#ffebee",
    "#ef9a9a",
    "#d32f2f",
    "#ff9800",
    "#b71c1c",
    "#fff3e0",
    "#ffcc80",
    "#bf360c",
    "#a5d6a7",
    "#7b1fa2",
    "#f57c00",
    "#e91e63",
    "#fbe9e7",
    "#ffab91",
    "#ff5722",
    "#bcaaa4",
    "#e64a19",
    "#f3e5f5",
    "#5d4037",
    "#ce93d8",
    "#4a148c",
    "#fce4ec",
    "#f48fb1",
    "#c2185b",
    "#880e4f",
    "#efebe9",
    "#795548",
    "#e65100",
    "#3e2723",
    "#9c27b0",
]



export default function ({data}) {
    const global = data?.results?.global ? data.results.global : {}
    const empresas = data?.results?.empresa ?   data.results.empresa.map((obj,i)=> ({...obj, fill: data.results.empresa.length>39  && i>39 ? colors[i-40] : colors[i]})) : []
    const pieData = data != [] ? Object.entries( global).map(i=> (
        { name: i[0] == "seen" ? "Visto" : "Não visto", 
        fill: i[0] == "seen" ? colors[2] : colors[5],
        value: Math.round(i[1]/Object.entries(global).reduce((acc, nextV)=> acc+nextV[1], 0)*100)})) : []
    return (
        <>
        { data != [] ? 
      
        <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            sx={{
                backgroundColor:"background.paper",
            }}
        >

            <GraphBox title={"Qtde de problemas por Empresa"}>


                <BarChart  data={empresas}
                    margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                    >
                    <XAxis dataKey="regional" stroke={grey[100]} angle={-60}  textAnchor="end">
                    </XAxis>
                    <YAxis stroke={grey[100]} >
                    </YAxis>
                    <Bar dataKey="qtd_problems" fill={cyan[700]}  >
                        <LabelList dataKey="qtd_problems" position="top" fill={grey[100]} />
                    </Bar>
                </BarChart>
            </GraphBox>
           

            <GraphBox title={"ACKs"}>


                <BarChart  data={empresas}
                    margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >
                    <XAxis dataKey="regional" stroke={grey[100]} angle={-60}  textAnchor="end">
                    </XAxis>
                    <YAxis  stroke={grey[100]}>
                    </YAxis>
                    <Bar dataKey="qtd_acks" fill={red[900]} >
                        <LabelList dataKey="qtd_acks" position="top" fill={grey[100]}  />
                    </Bar>
                </BarChart>
            </GraphBox>
            
            <GraphBox title={"Média tempo de Reação(minutos)"}>
                <BarChart  data={
                    empresas.map(obj=> ({...obj, mean_react_time: Math.round(obj.mean_react_time/60)}))
                }
                    margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >

                    <XAxis dataKey="regional" stroke={grey[100]} angle={-60}  textAnchor="end">

                    </XAxis>

                    <YAxis stroke={grey[100]} >

                    </YAxis>

                    <Bar dataKey="mean_react_time" fill={red[900]}  >
                    <LabelList dataKey="mean_react_time" position="top"  fill={grey[100]}  />
                    </Bar>
                </BarChart>
            </GraphBox>
            <GraphBox title={"Média tempo de Resolução(minutos)"}>
                <BarChart  data={
                    empresas.map(obj=> ({...obj, mean_resolve_time: Math.round(obj.mean_resolve_time/60)}))
                }
                margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >
                    <XAxis dataKey="regional" stroke={grey[100]} angle={-60}  textAnchor="end">

                    </XAxis>

                    <YAxis stroke={grey[100]}>

                    </YAxis>

                    <Bar dataKey="mean_resolve_time" fill={red[900]} label={{ angle: -60}} >
                        <LabelList dataKey="mean_resolve_time" position="top"  fill={grey[100]}  />
                    </Bar>
                </BarChart>
            </GraphBox>

            <GraphBox title={"Alarmes"}>
            <PieChart >
            <Pie data={pieData} 
                innerRadius={80}
                outerRadius={110}
                stroke={blueGrey[900]}
                strokeWidth={2}
                label={({name, value})=> `${name} ${value}%`}                
                />
                
            </PieChart>
            </GraphBox>
        </Box> :
            ""
        }
        </>
    )
}

