import { useEffect,useState } from "react";
import { AreaChart, Line, LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, stop, linearGradient } from 'recharts';
import api from "../services/api";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Paper, Typography } from "@mui/material";
import theme from "../theme";
import { blue, grey } from "@mui/material/colors";
import moment from "moment";
import { CloseOutlined } from "@mui/icons-material";

const convertUnixToDateTime = (unixTimestamp) => moment.unix(unixTimestamp).format('HH:mm:ss');

export default function Graph({itemid,open, codEmpresa, handlerClose}) {
  const [loading, setLoading] = useState(false);
  const CustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" >
          {payload?.map(v =>
            <p className="label">{isGBPs ? v.value + " Gbps" : v.value}</p>
          )}
          <p className="label">{convertUnixToDateTime(payload[0].payload.clock)} </p>
        </div>
      );
    }
    
    return null;
  };
    const [graphData, setGraphData] = useState([]);
    const [isGBPs, setIsGBPs] = useState(true);

  
    useEffect(() => {
      if (!open) {
        setGraphData([]);
        return
      }
        
      const fetchData = () => {
        setLoading(true);
        api.post('map/items-data', {
          cod_empresa: codEmpresa,
          itemid: itemid,
        }).then((res) => {
          
         const check = res.data.some((el) => Number(el.value) > 10000000)
          setIsGBPs(check);
          const tmp_data = res.data.map((item) => {
            const value = Number(item.value);
      
            return {
              ...item,
              value: check ?  (value / 1000000000).toFixed(2):value ,
              clock: Number(item.clock),
            };
          });
          
          
          setGraphData(tmp_data.sort((a, b) => a.clock - b.clock));
        }).finally(() => setLoading(false));
      };
  
      // Chama a função fetchData imediatamente
      fetchData();
  
  
      const intervalId = setInterval(fetchData, 30000);
  
      // Função de limpeza para cancelar o intervalo quando o componente é desmontado
      return () => {
        clearInterval(intervalId);
      };
    }, [itemid, open]);
  
    return (
      <Dialog open={open} fullWidth={true}
        maxWidth="lg">
        <Box component="form"  sx={{ mt: 1 }}>
        <DialogTitle>
        </DialogTitle>
        <DialogContent>  
          <Box sx={{display: loading ? 'none': 'flex', justifyContent: 'center'}}>
                <AreaChart
                  width={1200}
                  height={300}
                  data={graphData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 20,
                  }}
                  >
                  <CartesianGrid fillOpacity={0.6} />
                  <XAxis
                    dataKey="clock"
                    tickFormatter={convertUnixToDateTime}
                    allowDataOverflow={false}
                    //interval={5}
                    
                    />
                  <YAxis></YAxis>
                  <Tooltip
                  contentStyle={{backgroundColor: grey[900]}} itemStyle={{ color: grey[100] }}
                  content={CustomTooltip}
                  ></Tooltip>
                    <Area
                      connectNulls
                      type="linear"
                      unit={!isGBPs ? '' : 'Gbps'}
                      strokeWidth={2}
                      dataKey="value"
                      name={"name"}
                      
                      stroke={blue[500]}
                      
                      />
                
                </AreaChart>

          </Box>
          {loading && <LinearProgress sx={{mt: 2}}  />}
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={handlerClose}>Fechar</Button>

        </DialogActions>
          </Box>
      </Dialog>
      
    );


    
    

}