import React, { memo } from 'react';
import ZabbixMap from './ZabbixMap'; // Importe o componente ZabbixMap
import api from '../services/api'
import ListContextMenu, { HandleMenuContext, MenuContextProvider, useMenuContext } from './MenuContext';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, FormControl, FormControlLabel, IconButton, MenuItem, OutlinedInput, Paper, Select, Switch, TextField, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { grey } from '@mui/material/colors';
import { CachedOutlined, Info } from '@mui/icons-material';
import FormDialog from '../Empresa/FormDialog'
import Draggable from 'react-draggable';
import theme from '../theme';
import { useNavigate, useParams } from 'react-router-dom';
function App() {
  // Dados do mapa do Zabbix
  const [map, setMap] = React.useState(false)
  const [imgs, setImgs] = React.useState([])
  const [reload, setReload] = React.useState(false)
  const [id, setId] = React.useState(false)
  const [sysmapid, setSysmapid] = React.useState(null)
  const [maplist, setMaplist] = React.useState([])
  const [hosts, setHosts] = React.useState([])
  const [items, setItems] = React.useState([])
  const [host, setHost] = React.useState(null)

  const [empresas, setEmpresas] = React.useState([])
  const [empresa, setEmpresa] = React.useState(null)
  const [openBackDrop, setOpenBackDrop] = React.useState(false)
  const [problems, setProblems] = React.useState([])
  const [handlerDialogEmpresa, setHandlerDialogEmpresa] = React.useState(false)
  const [cache, setCache] = React.useState(false)
  const urlParams = useParams()
  const handleSwitchChange = (event) => {
    setCache(event.target.checked);
  };
  const navigate = useNavigate()
  React.useEffect(() => {

    if (empresa == null) return
    const { cod_empresa } = empresa
    setMaplist([])


    cache ?
      api.get(`map/list/cache/${cod_empresa}`).then(res => setMaplist(res.data))
      :
      api.get(`map/list/${cod_empresa}`).then(res => { setMaplist(res.data); })
  }, [cache])

  React.useLayoutEffect(() => {
    api.get('map/all').then(res => {
      setEmpresas(res.data)
      document.title = "Horus - Diagramas"
    })
  }, [])
  React.useEffect(() => {
    let intervalId;

    // Função para buscar as problems da empresa e atualizar o estado
    const fetchProblems = () => {
      if (empresa == null) return;

      api.get(`/dashboard/empresa/${empresa.cod_empresa}`)
        .then((res) => {
          setProblems(res.data);
        })
        .finally(() => setReload(false)); // Redefine o estado "reload" para "false" após a busca ser concluída
    };

    // Realiza a busca inicial ao montar o componente
    fetchProblems();

    // Define o intervalo de 30 segundos para buscar as problems periodicamente
    intervalId = setInterval(fetchProblems, 30000);

    // Limpa o intervalo quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, [empresa, reload]);

  function getItems(hostids) {
    if (hostids === undefined) return
    api.post('map/items', {
      hosts: hostids,
      cod_empresa: empresa.cod_empresa
    }).then(res => {
      const items = res.data.map(item => {
        return {
          key: `${item.hosts[0].host}:${item.key_}.last(0)`, lastValue: item.lastvalue, itemid: item.itemid,
          title: `${item.hosts[0].host} - ${item.name
            // .replace(/ifHCInOctets\.Virtual/g, "Entrada - ")
            // .replace(/ifHCOutOctets\.Virtual/g, "Saída - ")
            // .replace(/ifHCInOctets/g, "Entrada - ")
            // .replace(/ifHCOutOctets/g, "Saída - ")
            }`
        }
      });
      setItems(items)


    });
  }

  React.useEffect(() => {
    if (empresas.length == 0 || !urlParams.cod_empresa) return
    const empresa_temp = empresas.find(e => e.cod_empresa == urlParams.cod_empresa)
    if (urlParams?.cod_empresa) {
      setEmpresa(empresa_temp)
    }
    setMaplist([])
    setSysmapid(null)
    setHost(null)
    setHosts([])

    const url = cache ? `map/list/cache/${empresa_temp?.cod_empresa}` :
      `map/list/${empresa_temp.cod_empresa}`
    api.get(url).then(res => setMaplist(res.data))

  }, [empresas, urlParams.cod_empresa])




  React.useLayoutEffect(() => {
    if (empresa == null || maplist.length == 0) return
    if (urlParams?.mapid) {
      const map = maplist.find(m => m.sysmapid == urlParams.mapid)
      setSysmapid(map)
      setMap([])
      setHosts([])
      setHost(null)
      setHosts([])
      if (empresa === null | map === null) return;
      setOpenBackDrop(true)
      const url = cache ? `/map/get/cache/${empresa.cod_empresa}/${map.sysmapid}` : `/map/get/${empresa.cod_empresa}/${map.sysmapid}`
      api.get(url).then(res => {
        setImgs(res.data.imgs)
        setMap(res.data.map)
        setHosts(res.data.hosts)
        getItems(res.data.hosts.map(host => host[1]))
        document.title = `${map.name}`
        setOpenBackDrop(false)
      }).catch(err => setOpenBackDrop(false))



    }


  }, [maplist, urlParams.mapid])

  React.useLayoutEffect(() => {
    if (empresa == null || sysmapid == null || hosts.length == 0) return
    if (urlParams?.host) {
      const host_temp = hosts.find(h => h[1] == urlParams.host)
      setHost(host_temp)
    }

  }, [hosts, urlParams.host])




  return (
    <>
      <Box sx={{ zIndex: 100, position: 'absolute', backgroundColor: 'rgb(0,0,0,0)' }}>
        <FormControl
          sx={{ m: 1, width: 200, mt: 3 }}
        >
          <Autocomplete
            sx={{
              bgcolor: theme.palette.mode == "dark" ? grey[900] : grey[100]
            }}
            size="small"
            id="tags-outlined"
            options={empresas}
            onChange={(event, newValue) => {
              if (newValue === null) {

                navigate(`/map`)
                setMaplist([])
                setSysmapid(null)
                setHost(null)
              }else{
                navigate(`/map/${newValue?.cod_empresa}`)
              }
            }}
            key={'mapList'}
            isOptionEqualToValue={(option, value) => option.cod_empresa == value.cod_empresa}
            getOptionLabel={(option) => option.nome_fantasia}
            value={empresa}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Empresa"
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 300, mt: 3 }} >

          <Autocomplete
            sx={{
              bgcolor: theme.palette.mode == "dark" ? grey[900] : grey[100]
            }}
            size="small"
            id="tags-outlined"
            options={maplist}
            onChange={(event, newValue) => {
              newValue === null ? navigate(`/map/${empresa.cod_empresa}`) :
                navigate(`/map/${empresa.cod_empresa}/${newValue.sysmapid}`)

            }}
            key={'mapSearch'}
            isOptionEqualToValue={(option, value) => option.sysmapid == value.sysmapid}
            getOptionLabel={(option) => option.name}
            value={sysmapid}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mapa"
              />
            )}
          />

        </FormControl>
        <FormControl sx={{ m: 1, width: 350, mt: 3 }} >

          <Autocomplete
            sx={{
              bgcolor: theme.palette.mode == "dark" ? grey[900] : grey[100]
            }}

            size="small"
            id="tags-outlined"
            options={hosts}
            onChange={(event, newValue) => {
              if (newValue === null){

                navigate(`/map/${empresa.cod_empresa}/${sysmapid.sysmapid}`)
                setHost(null)
              } else{
                navigate(`/map/${empresa.cod_empresa}/${sysmapid.sysmapid}/${newValue[1]}`)
              }
                

            }}
            key={'hostSearch'}
            isOptionEqualToValue={(option, value) => option[1] == value[1]}
            getOptionLabel={(option) => option[2] + " " + option[3]}
            value={host}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar host..."
              />
            )}
          />

        </FormControl>
        <FormControl sx={{ m: 1, mt: 3 }} >
          <Tooltip title={"Atualizar os problemas"}>

            <IconButton disabled={reload} variant='outlined' onClick={() => {
              setReload(true)
            }}>
              <CachedOutlined />
            </IconButton>
          </Tooltip>
        </FormControl>
        <FormControl sx={{ m: 1, mt: 3 }} >
          <Tooltip title={"Informação da Empresa"}>
            <span>

              <IconButton disabled={empresa == null} variant='outlined' onClick={() => {

                api.get(`/empresa/find/cod_empresa/${empresa.cod_empresa}`).then(res => {
                  setId(res.data.__data__.id)
                  setHandlerDialogEmpresa(true)
                })
              }}>
                <Info />
              </IconButton>
            </span>
          </Tooltip>
        </FormControl>
        <FormControlLabel sx={{ m: 1, mt: 3 }}
          label=" Cache"
          control={
            <Switch
              checked={cache}
              onChange={handleSwitchChange}
              name="cacheSwitch"
              color="primary"
            />
          }
        />
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HandleMenuContext>
        <FormDialog open={handlerDialogEmpresa} codEmpresa={empresa?.codEmpresa} id={id} handlerClose={() => setHandlerDialogEmpresa(false)} />
        <ZabbixMap map={map} hosts={hosts} host={host} items={items} imgs={imgs} problems={problems} cod_empresa={empresa?.cod_empresa} />
      </HandleMenuContext>
    </>

  );
}

export default memo(App)