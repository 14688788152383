import React from 'react'
import api from '../services/api'
import { Box } from '@mui/system'
import { Container, TableContainer, ThemeProvider } from '@mui/material'
import { Paper } from '@mui/material'
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material'
import theme from '../theme'
import CircleIcon from '@mui/icons-material/Circle';
import { color } from '@mui/system'
import { green, grey } from '@mui/material/colors'
import moment from "moment";
import 'moment/locale/pt-br'

export default function () {
    const [users, setUsers] = React.useState([])
    const [usersDashboard, setUsersDashboard] = React.useState([])
    const getUserOnline = () => {
        api.get('user/online').then(res => {setUsers(res.data)
        })
    }
    const getUserDasboard = () => {
        api.get('ws/users_dashboard').then(res => {setUsersDashboard(res.data)
        })
    }
    React.useEffect(() => {
        getUserOnline()
        getUserDasboard()
        document.title = "Horus - Usuários Ativos"
    }, [])
    


    React.useEffect(() => {
        const interval = setInterval(getUserOnline, 15000)
        const interval2 = setInterval(getUserDasboard, 15000)
        return (() => {
            clearInterval(interval)
            clearInterval(interval2)
        })
    }, [])

    return (
    <ThemeProvider theme={theme}>
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4, gap:2, display:'flex', flexDirection:'column' }}>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" >Usuário Online</TableCell>
                                <TableCell align="center">Tempo login</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow
                                    key={row.username}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center"><CircleIcon sx={{color: green[800], mr:1,fontSize: 10}}/>
                                    {row.username}</TableCell>
                                    <TableCell align="center">{moment(row.created_at).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(row.created_at).locale('pt-br').fromNow()})</TableCell>
                                   
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" >Usuários Alarmes</TableCell>
                                <TableCell align="center">Tempo login</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersDashboard.map((row) => (
                                <TableRow
                                key={row.username}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center"><CircleIcon sx={{color: green[800], mr:1,fontSize: 10}}/>
                                    {row.username}</TableCell>
                                    <TableCell align="center">{moment(row.updated_at).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(row.updated_at).locale('pt-br').fromNow()})</TableCell>
                                   
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </Container>
    </ThemeProvider>

    )
}