import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../theme'
import api from '../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'

export default function () {
    const navigate = useNavigate()
    const [handlerDialog, setHandlerDialog] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const auth = useAuth()
    React.useEffect(() => {
        setUpdate(false)
        updateData()

    }, [handlerDialog, update])
    React.useEffect(()=>{
        document.title = "Horus - PPIS"
      },[])

    const [data, setData] = React.useState()
    const columns = [

        {
            name: 'cod_ppi',
            label: "PPI",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'id',
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: 'description',
            label: "Descrição",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'cod_empresa',
            label: "Empresa",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: 'group',
            label: "GRUPO",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) =>
                    <>
                        <Button disabled={!auth.hasPerm('ppi.edit')} sx={{ color: theme.palette.text.primary }} onClick={() => {
                            const id = data[dataIndex].id
                            setId(id)
                            setHandlerDialog(true)
                        }}>
                            <EditIcon />
                        </Button>
                        <Button disabled={!auth.hasPerm('ppi.delete')} sx={{ color: theme.palette.text.primary }} onClick={() => {
                            const id = data[dataIndex].id
                            const name = data[dataIndex].username
                            deleteItem(id, name)

                        }}>
                            <DeleteIcon />
                        </Button>
                    </>,
                filter: false,
            }
        }
    ]
    const deleteItem = (id, name) => {
        const conf = confirm(`Deseja realmente apagar o(a): ${name}`)
        if (!conf) return
        api.delete(`ppi/ppi/delete/${id}/`).then(() => {
            setUpdate(true)
        }).catch(err => alert('Algum erro aconteceu...'))
    }
    const updateData = () => {
        api.get('ppi/ppi/all')
            .then(res => {
                setData(res.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    navigate('/401')
                }
                setData()
            })
    }
    const showFormDialog = () => {
        setId()
        setHandlerDialog(true)
    }
    const options = {
        filterType: 'checkbox',
        rowsPerPageOptions: [30, 50, 100, 500],
        rowsPerPage: 30,
        rowHover: false,
        print: false,
        selectableRows: 'none',
        download: false,
        customToolbar: () => <IconButton onClick={showFormDialog} disabled={!auth.hasPerm('user.create')} sx={{ color: theme.palette.text.primary }}><AddIcon /></IconButton>,
        customToolbarSelect: () => { },
    }
    return (
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            <ThemeProvider theme={theme}>
                <FormDialog open={handlerDialog} id={id} handlerClose={() => setHandlerDialog(false)} updateData={updateData} />
                <Box sm={12} lg={10}>
                    <MUIDataTable
                        title={"PPIS"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </ThemeProvider>
        </Container>
    )
}