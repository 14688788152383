import { ThemeProvider } from '@emotion/react'
import { Box, Button, CircularProgress, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useState, useEffect, useLayoutEffect } from 'react'
import api from '../../services/api'
import theme from '../../theme'

import { useAuth } from '../../auth/AuthProvider'
import { redirect, useNavigate } from 'react-router-dom'
import EmpresaSelect from './EmpresaSelect'
import DateTimeToolbar from '../DateTimeToolbar'
import moment from 'moment'
import { textLabels } from '../textLabelsPT'
import { blueGrey, grey, lightBlue, lime, orange, red } from '@mui/material/colors'
import { Visibility } from '@mui/icons-material'
import FormDialog from './FormDialog'
export default function () {

  const [empresa, setEmpresa] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [acks, setAcks] = useState([])


  useLayoutEffect(() => {
    document.title = "Horus - Relatórios de API OFFLINE"
  })


  const [data, setData] = useState({ results: [], total: [], limit: 30 })
  const columns = [
    {
      name: "cod_empresa",
      label: "Empresa",
    },
    {
      name: "created_at",
      label: "Quando",
      options: {
        filter: false,
        customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "updated_at",
      label: "Finalizado",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const value = data.results[dataIndex].updated_at
          const created_at = data.results[dataIndex].created_at

          if (value == created_at) {
            
            return <span>{moment(value).add(2, 'minutes').format('DD-MM-YYYY HH:mm:ss')}</span>
          }
          return <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>
        },
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "time_seconds_api_down",
      label: "Duração",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {

          const humanizedDuration = moment.duration(value ==0 ? 120: value , "seconds").locale('pt-br').humanize();
          return <span>{ humanizedDuration}</span>
        }
      }
    },

  ]

  const fetchData = (page = 0, limit = 30) => {
    setData({ results: [] })
    const skip = page * limit
    setLoading(true)
    api.post('report/api_down', {
       skip, limit,
      cod_empresa: empresa,
      start: startDate,
      end: endDate,
    })
      .then(res => { 
        setData(data => ({ ...data, results: res.data.results, total: res.data.total, limit }))
      })
      .catch((err) => {
        console.log(err)
        setData({ results: [], total: [], limit: 30 })
      }).finally(() => {
        setLoading(false)

      })
  }


  const options = {
    search: false,
    textLabels: textLabels,
    filterType: 'checkbox',
    rowsPerPageOptions: [10, 30, 50, 100, 500, 1000, 5000],
    rowHover: false,
    print: true,
    selectableRows: 'none',
    download: true
    
  }
  return (


    <Container sx={{ mt: 4, mb: 4 }}>
      <ThemeProvider theme={theme}>
        <FormDialog
          open={openFormDialog}
          handlerClose={() => {
            setOpenFormDialog(false)
            setAcks([])
          }}
          acks={acks}
        />

        <Box sm={12} lg={12}>
          <MUIDataTable
            title={<Box
              display="flex"
            >
              <EmpresaSelect handler={v=>setEmpresa(v)}  multiple={false} />
              <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1, 'days')} />
              <DateTimeToolbar label={'Data Final'} handler={setEndDate} />
              <Button onClick={() => fetchData()} disabled={
                !(startDate) || !(endDate) || !empresa || loading
              }
                variant="contained" color="primary" sx={{ m: 1 }}>Buscar</Button>
            </Box>}
            data={data?.results}
            columns={columns}
            options={({
              ...options,
              textLabels: {
                ...textLabels, body: {
                  noMatch: loading ?
                    <CircularProgress color="info" />
                    : "Nenhum registro encontrado"
                }
              }
            })}
          />
        </Box>
      </ThemeProvider>
    </Container>

  )
}