import { CircularProgress, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";

import api from "../services/api";
import { grey } from "@mui/material/colors";
import MapIcon from '@mui/icons-material/Map';

export default function ButtonMap({ cod_empresa, host }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [mapList, setMapList] = React.useState([])
    return (
        <>
            <Tooltip title="Mapa de Rede">
                <IconButton
                    sx={{ color: grey[900] }}
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}

                    onClick={(e) => {
                        handleClick(e)
                        setLoading(true)
                        setMapList([])

                        api.get("/map/maplist/" + cod_empresa + "/" + host).then(res => {
                            if (res.data.length == 0) {
                                setMapList([{ name: "Sem Mapa" }])
                                return
                            }
                            setMapList(res.data)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }}
                >
                    <MapIcon />
                </IconButton>
            </Tooltip >
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {
                    loading ?
                        <MenuItem>
                            <CircularProgress size={20} />
                        </MenuItem>
                        :
                        mapList?.map((map) => (
                            <MenuItem key={map.sysmapid} 
                            disabled={map.name == "Sem Mapa"}
                            onClick={() => {
                                window.open(`/map/${cod_empresa}/${map.sysmapid}/${host}`)
                               
                            }}>
                                {map.name}
                            </MenuItem>
                        ))
                }


            </Menu>
        </>
    )
}

