import React from 'react'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import theme from '../theme';
import { Link } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningIcon from '@mui/icons-material/Warning';
import HistoryIcon from '@mui/icons-material/History';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PersonIcon from '@mui/icons-material/Person';
import ApiIcon from '@mui/icons-material/Api';

import { useAuth } from '../auth/AuthProvider';
import ListSubMenuHistorico from './listSubMenuHistorico';


export default function ListMenu() {
    const handlerLoc = (item) => JSON.parse(localStorage.getItem(item)) || false
    const auth = useAuth()
    const [open, setOpen] = React.useState({
        reportMenu: handlerLoc('reportMenu')
    });

    const handleClick = (item, value) => {
        setOpen(open => ({ ...open, [item]: !value }))
        localStorage.setItem(item, !value)
    };
    return (
        <>
            <ListItemButton onClick={() => handleClick('reportMenu', open.reportMenu)}
                sx={{
                    backgroundColor: open?.userMenu ? theme.palette.mode == 'dark' ? grey[900] : grey[100] : theme.palette.mode == 'dark' ? grey[900] : grey[100],
                    color: theme.palette.mode == 'dark' ? grey[100] : grey[900]
                }}>
                <ListItemIcon>
                    <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
                {open?.reportMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.reportMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{
                    pl: 1,
                    backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                    color: theme.palette.mode == 'dark' ? grey[200] : grey[900]
                }} >
                    <Link to={'/report/api-down'} style={{
                        textDecoration: 'none',
                        backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                        color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                    }}>
                        <ListItemButton  >
                            <ListItemIcon>
                                <ApiIcon />
                            </ListItemIcon>
                            <ListItemText primary="Api Offilne" />
                        </ListItemButton>
                    </Link>
                    {/* <Link to={'/report/problems'} style={{
                            textDecoration: 'none',
                            backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                            color: theme.palette.mode == 'dark' ? grey[100] : grey[900]
                            
                            }}>
                            <ListItemButton  >
                            <ListItemIcon>
                            <WarningIcon />
                            </ListItemIcon>
                            <ListItemText primary="Alarmes" />
                            </ListItemButton>
                            </Link> */}
                    <ListSubMenuHistorico />

                    {auth.hasPerm('report.get') ?
                        <>
                            <Link to={'/report/audit'} style={{
                                textDecoration: 'none',
                                backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                            }}>
                                <ListItemButton >
                                    <ListItemIcon>
                                        <ContentPasteSearchIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Auditoria" />
                                </ListItemButton>
                            </Link>

                            <Link to={'/report/users'} style={{
                                textDecoration: 'none',
                                backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                                color: theme.palette.mode == 'dark' ? grey[100] : grey[900]

                            }}>
                                <ListItemButton >
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Usuários" />
                                </ListItemButton>
                            </Link>
                        </>
                        : ''}
                </List>
            </Collapse>
        </>
            )
}
