import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import moment from "moment";
import 'moment/locale/pt-br'
import alarmPic from './alarm.png'
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import theme from '../theme';
import { lime, orange, red } from '@mui/material/colors';

const NotifyOptionsContext = React.createContext();
function filterMinutes(date, min, max) {
  const minute = moment().diff(date, 'minute');
  return minute >= min && minute <= max;
}
function setLocalStorage(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

function getLocalStorage(name) {
  const item = localStorage.getItem(name);
  return item ? JSON.parse(item) : null;
}

function NotifyOptionsProvider({ children, problems }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [options, setOptions] = useState(() => {
    const storedOptions = getLocalStorage('options');
    if (storedOptions === null) {
      const defaultOptions = {
        start: 0,
        end: 5000,
        interval: 5,
        sev3: true,
        sev4: true,
        sev5: true,
      };
      setLocalStorage('options', defaultOptions);
      return defaultOptions;
    }
    return storedOptions;
  });
  
  const [notificationsEnabled, setNotificationsEnabled] = useState(() => {
    const storedEnabled = getLocalStorage('notificationsEnabled');
    if (storedEnabled === null) {
      const defaultValue = true;
      setLocalStorage('notificationsEnabled', defaultValue);
      return defaultValue;
    }
    return storedEnabled;
  });


  function filterSev(sev) {
    if (options.sev3 && sev === 3) {
      return true;
    }
    if (options.sev4 && sev === 4) {
      return true;
    }
    if (options.sev5 && sev === 5) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const filteredProblems = problems?.problems?.filter(
      (p) =>
        filterMinutes(p.clock, options.start, options.end) &&
        p.acks === 0 &&
        filterSev(p.severity)
    );
    const originalTitle = document.title;

    document.title =
      filteredProblems?.length > 0
        ? `(${filteredProblems.length}) Horus - Alarmes`
        : 'Horus - Alarmes';

    return () => {
      document.title = originalTitle;
    };
  }, [problems, options.start, options.end, options.sev3, options.sev4, options.sev5]);


  const handleNotification = (filteredProblems) => {
    if(!notificationsEnabled) return
    if (filteredProblems?.length > 0) {
      if (Notification.permission === 'granted') {
        // A permissão já foi concedida, crie a notificação
        const notification = new Notification(`Há problemas no dashboard (${filteredProblems.length})`, {
          body: filteredProblems[0].name,
          icon: alarmPic,
        });
      } else if (Notification.permission !== 'denied') {
        // A permissão não foi negada, solicite a permissão
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            // O usuário concedeu a permissão, crie a notificação
            const notification = new Notification(`Há problemas no dashboard (${filteredProblems.length})`, {
              body: filteredProblems[0].name,
              icon: alarmPic,
            });
          }
        });
      }
    }
  };
  
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const filteredProblems = problems?.problems?.filter(
      (p) =>
        filterMinutes(p.clock, options.start, options.end) &&
        p.acks === 0 &&
        filterSev(p.severity)
    );
  
    handleNotification(filteredProblems);
  }, []);
  
  useEffect(() => {
    const filteredProblems = problems?.problems?.filter(
      (p) =>
        filterMinutes(p.clock, options.start, options.end) &&
        p.acks === 0 &&
        filterSev(p.severity)
    );
  
    handleNotification(filteredProblems);
  
    // Limpa o intervalo anterior, se existir
    if (intervalId) {
      clearInterval(intervalId);
    }
  
    // Configura um novo intervalo
    const newIntervalId = setInterval(() => {
      const filteredProblems = problems?.problems?.filter(
        (p) =>
          filterMinutes(p.clock, options.start, options.end) &&
          p.acks === 0 &&
          filterSev(p.severity)
      );
  
      handleNotification(filteredProblems);
    }, options.interval * 60000);
  
    // Armazena o ID do novo intervalo na variável de estado
    setIntervalId(newIntervalId);
  
    // Limpa o intervalo ao desmontar o componente
    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
  }, [ options, notificationsEnabled]);




  useEffect(() => {
    setLocalStorage('options', options);
  }, [options]);

  useEffect(() => {
    setLocalStorage('notificationsEnabled', notificationsEnabled);
  }, [notificationsEnabled]);

  return (
    <NotifyOptionsContext.Provider
      value={{
        options,
        setOptions,
        openDialog,
        setOpenDialog,
        notificationsEnabled,
        setNotificationsEnabled,
      }}
    >
      {children}
    </NotifyOptionsContext.Provider>
  );
}

function useNotifyOptions() {
  const context = useContext(NotifyOptionsContext);

  if (!context) {
    throw new Error('useNotifyOptions must be used within a NotifyOptionsProvider');
  }

  return context;
}

export const NotifyButton = React.memo((props) => {
  const { setOpenDialog } = useNotifyOptions();

  return (
    <>
      <NotifyDialog />
      <Tooltip title="Gerenciar notificações">
        <IconButton onClick={() => setOpenDialog(true)}>
          <AccessAlarmsIcon />
        </IconButton>
      </Tooltip>
    </>
  );
})

function NotifyDialog() {
  const {
    options,
    setOptions,
    openDialog,
    setOpenDialog,
    notificationsEnabled,
    setNotificationsEnabled,
  } = useNotifyOptions();

  const handleChange = (e) =>
    setOptions((options) => ({ ...options, [e.target.name]: e.target.value }));
  const handleCheckboxChange = (e) =>
    setOptions((options) => ({ ...options, [e.target.name]: e.target.checked }));

  const handleNotificationsToggle = () =>
    setNotificationsEnabled((prevEnabled) => !prevEnabled);

  return (
    <Dialog open={openDialog}>
      <FormControl fullWidth>
        <DialogTitle>Intervalos dos alarmes</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormControlLabel
              sx={{ p: 1 }}
              control={
                <Checkbox
                  checked={!notificationsEnabled}
                  onChange={handleNotificationsToggle}
                  name="notificationsEnabled"
                />
              }
              label="Desativar"
            />
            <TextField
              disabled={!notificationsEnabled}
              onChange={handleChange}
              margin="dense"
              name="start"
              label="Tempo de vida mínimo (minutos):"
              variant="outlined"
              size="small"
              type="number"
              value={options.start}
            />
            <TextField
              disabled={!notificationsEnabled}
              onChange={handleChange}
              margin="dense"
              name="end"
              label="Tempo de vida máximo (minutos):"
              variant="outlined"
              size="small"
              type="number"
              value={options.end}
            />
            <TextField
              disabled={!notificationsEnabled}
              onChange={handleChange}
              margin="dense"
              name="interval"
              label="Período entre as notificações (minutos):"
              variant="outlined"
              size="small"
              type="number"
              value={options.interval}
            />
            <FormControlLabel
              disabled={!notificationsEnabled}
              sx={{
                p: 1,
                color: theme.palette.mode === 'dark' ? lime[300] : lime[200],
              }}
              control={
                <Checkbox
                  checked={options.sev3}
                  onChange={handleCheckboxChange}
                  name="sev3"
                />
              }
              label="Severidade 3"
            />
            <FormControlLabel
              disabled={!notificationsEnabled}
              sx={{
                p: 1,
                color: theme.palette.mode === 'dark' ? orange[300] : orange[200],
              }}
              control={
                <Checkbox
                  checked={options.sev4}
                  onChange={handleCheckboxChange}
                  name="sev4"
                />
              }
              label="Severidade 4"
            />
            <FormControlLabel
              disabled={!notificationsEnabled}
              sx={{
                p: 1,
                color: theme.palette.mode === 'dark' ? red[400] : red[300],
              }}
              control={
                <Checkbox
                  checked={options.sev5}
                  onChange={handleCheckboxChange}
                  name="sev5"
                />
              }
              label="Severidade 5"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: theme.palette.text.primary }}
            onClick={() => setOpenDialog(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
}

export default NotifyOptionsProvider;
