import { ThemeProvider } from '@emotion/react'
import { Box, Button, CircularProgress, Container, IconButton, Paper } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useState, useEffect, useLayoutEffect } from 'react'
import api from '../../services/api'
import theme from '../../themeDash'

import { useAuth } from '../../auth/AuthProvider'
import { redirect, useNavigate } from 'react-router-dom'
import EmpresaSelect from './EmpresaSelect'
import DateTimeToolbar from '../DateTimeToolbar'
import moment from 'moment'
import { textLabels } from '../textLabelsPT'
import { blueGrey, grey, lightBlue, lime, orange, red } from '@mui/material/colors'
import { Visibility } from '@mui/icons-material'
import FormDialog from './FormDialog'
export default function () {

  const [empresas, setEmpresas] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [acks, setAcks] = useState([])


  useLayoutEffect(() => {
    document.title = "Horus - Relatórios de Alarmes"
  })


  const [data, setData] = useState({ results: [], total: [], limit: 30 })
  const columns = [
    {
      name: "event_id",
      label: "Evento ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "cod_empresa",
      label: "Empresa",
      options: {
        filter: false,
        sort: true,
        display: false,
        customFilterListOptions: { render: v => `Empresa: ${v}` },
      }
    },
    {
      name: "regional",
      label: "Regional",
      options: {
        filter: true,
        sort: true,
        display: true,
        customFilterListOptions: { render: v => `Regional: ${v}` },

      }
    },
    {
      name: "name",
      label: "Descrição",
      options: {
        filter: false,
        sort: false,
      }
    },

    {
      name: "hostid",
      label: "HostId",
      options: {
        display: false,
        filter: false,
        sort: false,
      }
    },
    {
      name: "hostname",
      label: "Host",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ip",
      label: "IP",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "severity",
      label: "Severidade",
      options: {
        display: false,
        filter: true,
        download: true,
        sort:true,
        customFilterListOptions: { render: v => `Severidade: ${
          v == 6 ? "Erro de API" :
            v == 5 ? "Desastre" :
              v == 4 ? "Alta" :
                v == 3 ? "Média" :
                  v == 2 ? "Atenção" :
                    v == 1 ? "Informação" : "Não classificado"
        }` },
        filterOptions: {
          names: [1,2,3,4,5,6],
          renderValue: v => {
            switch (v) {
              case 6:
                return "Erro de API"
              case 5:
                return "Desastre"
              case 4:
                return "Alta"
              case 3:
                return "Média"
              case 2:
                return "Atenção"
              case 1:
                return "Informação"
              default:
                return "Não classificado"
            }
          },
        },
      }
    },
    {
      name: "clock",
      label: "Quando",
      options: {
        filter: false,
        customBodyRender: value => <span>{moment.unix(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "clock_r",
      label: "Finalizado",
      options: {
        filter: false,
        customBodyRender: value => <span>{value ? moment.unix(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss') : "-"}</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "time_total",
      label: "Duração(min)",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const clock = data.results[dataIndex]?.clock
          const clock_r = data.results[dataIndex]?.clock_r
          const diffMilliseconds = moment.unix(clock_r).diff(moment.unix(clock));
          const humanizedDuration = moment.duration(diffMilliseconds).locale('pt-br').humanize();
          return <span>{!clock_r ? "" :   moment.duration(diffMilliseconds).locale('pt-br').minutes()  }</span>
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            

            const clock1 = obj1.rowData[8]
            const clock2 = obj2.rowData[8]
            const clock_r1 = obj1.rowData[9]
            const clock_r2 = obj2.rowData[9]
            const diffMilliseconds1 = moment.unix(clock_r1).diff(moment.unix(clock1));
            const diffMilliseconds2 = moment.unix(clock_r2).diff(moment.unix(clock2));
            const minutes1 = moment.duration(diffMilliseconds1).locale('pt-br').minutes()
            const minutes2 = moment.duration(diffMilliseconds2).locale('pt-br').minutes()
            
            return ((minutes1 == minutes2) ? 0 : ((minutes1 > minutes2) ? 1 : -1)) * (order === 'asc' ? 1 : -1)
            
          }
        }
      
      },
      },
    {
      name: "online_users",
      label: "Usuários Online",
      options: {
        filter: false,
        sort: false,
        donwload: true,
        customBodyRender: users => users?.map(user => user?.name).join(', ')
      }
    },
    {
      name: "acknowledges",
      label: "Ack",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value?.length ? <IconButton sx={{ color: grey[900] }} onClick={() => {
            setAcks(value)
            setOpenFormDialog(true)
          }
          }><Visibility /></IconButton> : "")
        },
        filter: false,
        sort: false,
        donwload:true,
      }
    },
    {
      name: "reaction_time",
      label: "Tempo de Reação(min)",
      options: {
        filter: false,
        sort: true,
        donwload: true,
        customBodyRenderLite: (dataIndex) => {
          const clock = data.results[dataIndex]?.clock
          const acks = data.results[dataIndex]?.acknowledges
          if (acks.length) {
            const acks_ipv7 = acks.filter(ack => ack.message.includes('Vistado por'))
            const ack = acks_ipv7.length ? acks_ipv7[0] : false

            if (!ack) return ""

            const diffMilliseconds = moment.unix(ack.clock).diff(moment.unix(clock));
            const minutes = moment.duration(diffMilliseconds).locale('pt-br').minutes()
            const humanizedDuration = moment.duration(diffMilliseconds).locale('pt-br').humanize();
            return <span
              //red if more than 45 minutes
              style={{ color: diffMilliseconds > 2700000 ? red[800] : 'black' }}
            >{minutes}</span>
          }
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const clock1 = obj1.rowData[8]
            const clock2 = obj2.rowData[8]
            const acks1 = obj1.rowData[12].filter(ack => ack.message.includes('Vistado por')) ? obj1.rowData[12] : []
            const acks2 = obj2.rowData[12].filter(ack => ack.message.includes('Vistado por')) ? obj2.rowData[12] : []
            const reactTime1 = acks1.length ? moment.unix(acks1[0].clock).diff(moment.unix(clock1)) : 0
            const reactTime2 = acks2.length ? moment.unix(acks2[0].clock).diff(moment.unix(clock2)) : 0
            const minute1 = moment.duration(reactTime1).locale('pt-br').minutes()
            const minute2 = moment.duration(reactTime2).locale('pt-br').minutes()
            return order === 'asc' ? minute1 - minute2 : minute2 - minute1
            
          }
        }
      }
    }
  ]

  const fetchData = (page = 0, limit = 30) => {
    setData({ results: [] })
    const skip = page * limit
    setLoading(true)
    api.post('report/alarmes', {
      page, skip, limit,
      empresas,
      start: startDate,
      end: endDate,
    })
      .then(res => {
        setData(data => ({ ...data, results: res.data.results, total: res.data.total, limit }))
      })
      .catch((err) => {
        console.log(err)
        setData({ results: [], total: [], limit: 30 })
      }).finally(() => {
        setLoading(false)

      })
  }


  const options = {
    search: true,
    textLabels: textLabels,
    filterType: 'checkbox',
    rowsPerPageOptions: [10, 30, 50, 100, 500, 1000, 5000],
    rowHover: false,
    print: false,
    selectableRows: 'none',
    download: true,
    //customToolbar,
    customToolbarSelect: () => { },
    setRowProps: (row, dataIndex, rowIndex) => {
      switch (Number(row[7])) {

        case 5:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? red[400] : red[300]
            },
          }
        case 4:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? orange[300] : orange[200]
            },
          }
        case 3:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lime[300] : lime[200]
            },
          }
        case 2:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lightBlue[300] : lightBlue[200],

            },
          }
        case 1:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? blueGrey[300] : blueGrey[200]

            },
          }
        default:
          break;
      }
    },
    onDownload: (buildHead, buildBody, columns, data1) => {
      
      const customData = data?.results.map((row,i) => {
        const start = moment.unix(row.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')
        const end = row.clock_r ? moment.unix(row.clock_r).locale('pt-br').format('DD-MM-YYYY HH:mm:ss') : "-"
        const diffMilliseconds = row.clock_r ? moment.unix(row.clock_r).diff(moment.unix(row.clock)) : 0;
        const duratinMinutes = moment.duration(diffMilliseconds).locale('pt-br').minutes()
        const reactionMinutes = row.acknowledges.length ? moment.unix(row.acknowledges[0].clock).diff(moment.unix(row.clock)) : ""

       return {
        index:i,
        data: [
          row.regional || "",
          row.name || "",
          row.hostname || "",
          row.ip || "",
          row.severity || "",
          moment.unix(row.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss'),
          row.clock_r ? moment.unix(row.clock_r).locale('pt-br').format('DD-MM-YYYY HH:mm:ss') : "",
          duratinMinutes || "",
          row.online_users.map(user => user.name).join(', ') || "",,
          row.acknowledges.map(ack => ack.message).join(', ')|| "",
          
          reactionMinutes ? moment.duration(reactionMinutes).locale('pt-br').minutes() : "",
          

        ]

       }
        
      })
      const columsTemp = [ "Regional", "Descrição", "Host", "IP", "Severidade", "Quando", "Finalizado", "Duração(min)", "Usuários Online", "Ack", "Tempo de Reação(min)"]
      const customColumns =  columns.filter((col, i)=> columsTemp.some(c => c === col.label))
      return "\uFEFF" + buildHead(customColumns) + buildBody(customData);
      
    } 
      
   
  }
  return (


    <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
      <ThemeProvider theme={theme}>
        <FormDialog
          open={openFormDialog}
          handlerClose={() => {
            setOpenFormDialog(false)
            setAcks([])
          }}
          acks={acks}
        />

        <Box sm={12} lg={12}>
          <Paper
            sx={{
              display: 'flex',
              bgcolor: theme.palette.grey[900],
              p: 1,
              boxShadow: 1,
              mb:1
            }}

          >
            <EmpresaSelect handler={setEmpresas} />
            <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1, 'days')} />
            <DateTimeToolbar label={'Data Final'} handler={setEndDate} />
            <Button onClick={() => fetchData()} disabled={
              !(startDate) || !(endDate) || !(empresas.length > 0) || loading
            }
              variant="contained" color="primary" sx={{ m: 1 }}>Buscar</Button>
          </Paper>
          <MUIDataTable
            title={"Histórico de Alarmes"}
            data={data?.results}
            columns={columns}
            options={({
              ...options,
              textLabels: {
                ...textLabels, body: {
                  noMatch: loading ?
                    <CircularProgress color="info" />
                    : "Nenhum registro encontrado"
                }
              }
            })}
          />
        </Box>
      </ThemeProvider>
    </Container>

  )
}