import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from "../../services/api";



export default function EmpresaSelect({handler, value,disabled=false, multiple=true,...props }) {
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {
        setLoading(true)
        api.get('/empresa/report/all').then(res => {
          setEmpresas(res.data)
        }).finally(() => setLoading(false))
      }, [])
  return (
    <FormControl 
    sx={{mt: 1, minWidth: 200}}
    {...props}
    >
      <Autocomplete
        multiple={multiple}
        size='small'
        disabled={disabled}
        id="empresas-outlined"
        options={empresas}
        onChange={(event, newValue) => {
          setEmpresa(newValue)
          if (Array.isArray(newValue)){

            handler(newValue.map(empresa => empresa.cod_empresa))
            return
          }
          handler(newValue?.cod_empresa)
        }}
       key={(option) => option?.cod_empresa}
       loadingText={"Buscando..."}
       noOptionsText={"Sem opções"}
        loading={loading}
        isOptionEqualToValue={(option, value) => value?.cod_empresa  && option?.cod_empresa == value?.cod_empresa }
        getOptionLabel={(option) => option?.nome_fantasia || option?.cod_empresa }
        value={empresa}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
    
            {...params}
            label="Empresa"
          />
        )}
      />
    </FormControl>
  )
}