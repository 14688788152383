import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton, Table, TableCell, TableRow } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useState, useEffect } from 'react'
import theme from '../../theme'
import api from '../../services/api'


import { useAuth } from '../../auth/AuthProvider'
import { redirect, useNavigate } from 'react-router-dom'
import EmpresaSelect from '../EmpresaSelect'
import UserSelect from '../UserSelect'
import DateTimeToolbar from '../DateTimeToolbar'
import moment from 'moment'
import { textLabels } from '../textLabelsPT'

export default function () {
    const navigate = useNavigate()
    const auth = useAuth()
    const [handlerDialog, setHandlerDialog] = useState(false)
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState()
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [user, setUser] = useState()
    useEffect(() => {
        document.title = "Horus - Relatórios de Usuarios ONLINE"
    })



    const [data, setData] = useState([])
    const columns = [
        {
            name: 'user',
            label: "Nome",
            options: {
                filter: false,
                sort: false,
            }
        },
        // {
        //     name: 'created_at',
        //     label: "Início:",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY hh:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,
        //     }
        // },
        // {
        //     name: 'updated_at',
        //     label: "Término:",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY hh:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,
        //     }
        // },
        {
            name: "info",
            label: "Informações",
            options: {
                display: false,
            }
        },
        {
            name: 'total_time',
            label: "Tempo total:",
            options: {
                filter: false,
                sort: false,
                customBodyRender: value => <span>{moment.duration(value, 'seconds').humanize()}</span>,
            }
        },

    ]

    const updateData = (page = 0, limit = 30) => {
        const skip = page * limit

        api.post('report/suporte_online', {
            page, skip, limit,
            start: startDate,
            end: endDate,
            technician_id: [user]

        })
            .then(res => {
                console.log(res)
                setData(res.data)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    navigate('/401')
                }
                setData([])
            })
    }


    const options = {
        search: false,
        textLabels: textLabels,
        filterType: 'checkbox',
        rowsPerPageOptions: [10, 30, 50, 100, 500, 1000, 2000],

        rowHover: true,
        print: true,
        selectableRows: 'none',
        //expand info
        renderExpandableRow: (rowData, rowMeta) => {
            console.log(rowData);

            const colSpan = rowData.length + 1;
            return (
                    rowData[1].map((item, i) =>

                        <TableRow>
                            <TableCell key={i + 1} sx={{minWidth: 200}}>{
                                moment(item.created_at).locale('pt-br').format('DD-MM-YYYY HH:mm:ss') 
                            }</TableCell>
                            <TableCell key={i + 1}>{
                                moment(item.updated_at).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')
                            }</TableCell>
                            <TableCell key={i + 1}  >{moment.duration(item.total_online_time_seconds, 'seconds').humanize()}</TableCell>
                        </TableRow>


                    )
                
            );
        },
        download: true,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: () => true,
    }
    return (
        <Container sx={{ mt: 4, mb: 4 }}>
            <ThemeProvider theme={theme}>

                <Box sm={12} lg={12}>
                    <MUIDataTable
                        title={<Box
                            display={'flex'}
                        >

                            <UserSelect handler={setUser} />
                            <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1, 'days')} />
                            <DateTimeToolbar label={'Data Final'} handler={setEndDate} />
                            <Button sx={{ m: 1 }}
                                disabled={!(startDate) || !(endDate) || !(user)}
                                onClick={() => updateData()} variant="contained" color="primary">Buscar</Button>

                        </Box>}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </ThemeProvider>
        </Container>
    )
}